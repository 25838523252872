<template>
	<b-modal size="xl" ref="modalEditResaDates">
		<template slot="modal-header" slot-scope="{ close }">
			<i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
			<h2 class="mx-auto my-auto">{{ $t('monte.reservation.modifier_commande') }}</h2>
		</template>
		<template slot="default">
			<div class="form-group">
				<label>{{ $t('monte.reservation.date_commande') }}</label>
				<e-datepicker v-model="form.order_date" />
			</div>
			<div class="form-group">
				<label>{{ $t('monte.reservation.date_send_order') }}</label>
				<e-datepicker v-model="form.sendorder_date" />
			</div>
			<div class="form-group">
				<label>{{ $t('monte.reservation.supported_date') }}</label>
				<e-datepicker v-model="form.supported_date" />
			</div>
			<div class="form-group">
				<label>{{ $t('monte.reservation.expedition_date') }}</label>
				<e-datepicker v-model="form.expedition_date" />
			</div>
			<div class="form-group">
				<label>{{ $t('monte.reservation.delivery_date') }}</label>
				<e-datepicker v-model="form.delivery_date" />
			</div>
		</template>

		<template slot="modal-footer" class="justify-content-center">
			<b-button variant="primary" @click="submitModal()" :disabled="processing">
				<font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
				{{ $t('global.sauvegarder') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script type="text/javascript">
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'ModalEditDates',
		props: {
			reservationOrder: { type: Object, default: null }
		},
		data () {
			return {
				processing: false,
				default_form: {
					order_date: null,
					sendorder_date: null,
					supported_date: null,
					expedition_date: null,
					delivery_date: null
				},
				form: null,
			}
		},
		created() {
			this.preinit_component()
		},
		methods: {
			preinit_component() {
				this.resetModal()
			},
			prepareModal() {
				if(!this.reservationOrder) return

				const monte_order = this.reservationOrder.order
				const keys = Object.keys(this.default_form)

				keys.forEach(key => {
					this.form[key] = new Date(monte_order[key])
				})
			},
			openModal() {
				if(!this.form) {
					this.resetModal()
				}

				this.$refs.modalEditResaDates.show()
			},
			closeModal() {
				this.$refs.modalEditResaDates.hide()
			},
			resetModal() {
				this.form = _cloneDeep(this.default_form)
			},
			submitModal() {
				this.$emit('ok', this.form)
				this.closeModal()
			}
		},
		watch: {
			reservationOrder() {
				this.prepareModal()
			}
		}
	}

</script>